<template>
  <div>
    <b-card
      v-if="$store.state.auth.role === 1"
      no-body
    >
      <b-card-header>
        <h5>Locations</h5>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover
          title="Ajouter"
          variant="outline-primary"
          :to="{ name: 'locations-create' }"
        >
          Ajouter
        </b-button>
      </b-card-header>
      <b-table
        v-if="rentals && rentals.length > 0"
        class="position-relative"
        :items="rentals"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucune location trouvée"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(id)="data">
          <div>
            <small>
              <span>LOC</span>{{ data.item.id }}
            </small>
          </div>
        </template>

        <template #cell(createdAt)="data">
          <b-link
            :to="{ name: 'locations-view', params: { id: data.item.id} }"
            class="font-weight-bold"
          >
            {{ data.item.user_id.enterprise }}
          </b-link>
        </template>

        <template #cell(deviceId)="data">
          <small>
            {{ data.item.device.name }}
          </small>
        </template>

        <template #cell(startDate)="data">
          <div>
            <small>
              {{ data.item.startDate | displayDate }}
            </small>
          </div>
        </template>

        <template #cell(endDate)="data">
          <small>
            {{ data.item.endDate | displayDate }}
          </small>
        </template>

        <template #cell(state)="data">
          <b-badge
            pill
            :variant="`light-${resolveStateVariant(data.item)}`"
            class="text-capitalize d-flex justify-content-center"
          >
            {{ resolveState(data.item) }}
          </b-badge>
        </template>

        <template #cell(comment)="data">
          <div>
            <small>
              {{ data.item.comment | truncate(20) }}
            </small>
          </div>
        </template>

        <template #cell(personInCharge)="data">
          <div>
            <small>
              {{ data.item.personInCharge }}
            </small>
          </div>
        </template>

        <template #cell(action)="data">
          <div class="d-flex justify-content-end">
            <b-link
              :to="{ name: 'locations-edit', params: { id: data.item.id} }"
            >
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-tooltip.hover
                title="Modifier"
                variant="flat-warning"
                class="btn-icon rounded-circle"
              >
                <feather-icon
                  icon="SettingsIcon"
                  size="20"
                />
              </b-button>
            </b-link>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-tooltip.hover
              title="Supprimer"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="deleteRental(data.item)"
            >
              <feather-icon
                icon="DeleteIcon"
                size="20"
              />
            </b-button>
          </div>
        </template>
      </b-table>
      <div
        v-else
        class="text-center"
      >
        <b-spinner
          v-if="isLoading"
          class="mt-5 mb-5"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BTable, BBadge,
  BCardHeader, VBTooltip, BSpinner, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BBadge,
    BCardHeader,
    BSpinner,
    BLink,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      rentals: null,
      tableColumns: [
        { key: 'id', label: 'N°', sortable: true },
        { key: 'createdAt', label: 'Client', sortable: false },
        { key: 'deviceId', label: 'Device', sortable: false },
        { key: 'startDate', label: 'Début', sortable: true },
        { key: 'endDate', label: 'Fin', sortable: true },
        { key: 'state', label: 'État', sortable: true },
        { key: 'comment', label: 'Commentaire', sortable: false },
        { key: 'personInCharge', label: 'Personne', sortable: true },
        { key: 'action', label: 'Actions', sortable: false },
      ],
      sortBy: 'id',
      isSortDirDesc: true,
      stateOptions: ['A faire', 'En cours', 'Terminé', 'Retard'],
      // pagination
      perPage: 10,
      currentPage: 1,
      // state
      today: new Date(),
    }
  },
  mounted() {
    this.getRentals()
  },
  methods: {
    getRentals() {
      this.isLoading = true
      store.dispatch('rental/findAllRentals')
        .then(response => {
          this.rentals = response
          if (this.rentals && this.rentals.length > 0) {
            this.rentals.forEach(rental => {
              // hydrate client data
              if (store.state.user.usersList) {
                const [users] = store.state.user.usersList.filter(user => user.id === rental.user_id)
                // eslint-disable-next-line no-param-reassign
                rental.user_id = users
              }
            })
          }
          this.isLoading = false
        })
        .catch(err => {
          console.log('err', err)
          this.rentals = null
          this.isLoading = false
        })
    },
    deleteRental(rental) {
      this.$swal({
        title: 'Êtes-vous sûr de vouloir supprimer ?',
        text: 'Cette action est irréversible!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer!',
        cancelButtonText: 'Annuler',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('rental/deleteRental', rental.id)
            .then(() => {
              if (rental.files && rental.files.length > 0) {
                rental.files.forEach(file => {
                  store.dispatch('file/deleteFile', file.id)
                })
              }
              this.$swal({
                icon: 'success',
                title: 'Supprimé!',
                text: 'L\'évènement a bien été supprimé',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
          this.getRentals()
        }
      })
    },
    resolveStateVariant(data) {
      if (Date.parse(data.endDate) < Date.parse(this.today.toString())) return 'success'
      if (Date.parse(data.startDate) > Date.parse(this.today.toString())) return 'warning'
      return 'info'
    },
    resolveState(data) {
      if (Date.parse(data.endDate) < Date.parse(this.today.toString())) return 'Terminé'
      if (Date.parse(data.startDate) > Date.parse(this.today.toString())) return 'A venir'
      return 'En cours'
    },
  },
}
</script>
